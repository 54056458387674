
import { QuoteActions } from "@/store/vm/quote/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";

export default defineComponent({
  name: "quote-list",
  components: {},
  props: {
    widgetClasses: String,
  },
  emits: ["del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();

    onMounted(() => {
      store.dispatch(QuoteActions.GET_ALL_QUOTE).catch((e) => {
        console.log(e);
      });
    });

    const list = computed(() => {
      return store.state.QuoteModule.list;
    });

    const count = computed(() => {
      return store.state.QuoteModule.list.length;
    });

    const delQuote = (quoteId) => {
      emit("del", quoteId);
    };

    const modalUpsertId = store.state.QuoteModule.modalUpsertId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    return {
      list,
      checked,
      count,
      delQuote,
      modalUpsertId,
      lang,
      moment,
    };
  },
});
